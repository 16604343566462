

:root {

 --d-black: #000;
 --d-blue: #003470;
 --d-orange: #F88125;
 --d-yellow: #FED01B;
 --d-white: #fff;
 --d-red: red;
 --l-blue: #0975de;
 --r-blue: #1B59AE;
 --d-gray: #636464;
 --l-gray: #63646424;
 --r-gray: #808181;
 --l-orange: #FA600A;
 --bg-sky: #E6F5FF;
 --d-green: #2ed138;
 --s-gray: #80808061;
 --an-green: #29cf29;

 --d-purple: #9466DB;

 --lb-blue: #6baceb42;
 --lg-green: #29cf2940;
 --lg-red: #ff00001f;

 --gr-green:  transparent linear-gradient(90deg, #29B72E 0%, #8CE88F 100%) 0% 0% no-repeat padding-box;
 --gr-purple: transparent linear-gradient(90deg, #9466DB 0%, #9466DB 51%, #D0B2FF 100%) 0% 0% no-repeat padding-box;

 --bl-blue: #E2EFFF;
 --bl-green: #DEFFE3;
 --bl-orange: #FFE6D8;
 --bl-yellow: #FFFBC8;
 --bl-purple: #9466db3b;
 
 --xb-font: 70px;
 --b-font: 50px;
 --sb-font:40px;
 --xxl-font: 30px;
 --xl-font: 28px;
 --md-font: 24px;
 --xmd-font: 20px;
 --smm-font: 18px;
 --sm2-font: 14px;
 --sm-font: 16px;
 --ss-font: 12px;
 --xss-font: 10px;

 --input-shadow:0 0px 10px #0034703b, 0 0px 0px #00347085;
 --b-shadow:  0px 0px 61px #D6DDFBC2;
 --c-shadow:  0px 0px 30px #0000001A;


 --w-full:100%;
    


 --width: 16px;
 --height: calc(var(--width) * 1.5);
 --border-color: black;

}

@media only screen and (max-width: 990px){
    :root{

        --xmd-font:18px;
     
    }
}
@media only screen and (max-width: 1200px){
    :root{
        --b-font: 40px;
  
     
    }
}
@media only screen and (max-width: 1399px){
    :root{
        --md-font: 20px;
       
    }
}