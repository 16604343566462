@media only screen and (max-width: 1440px) {}

@media only screen and (max-width: 1368px) {}

/* @media only screen and (min-width: 200px) and (max-width: 574px){
  main{
    padding: 0 0 710px 0;
  }
  .footer_wrapper{
    height: 710px;
  }
} */
@media only screen and (max-width: 579px){
  .footer_wrapper {
    height: 800px !important;
  }
  .footer_logo{
    max-width: 50%;
  }
}
@media only screen and (min-width: 580px) and (max-width: 767px) {
  .footer_wrapper {
    height: 540px;
  }
}
@media only screen and (min-width: 200px) and (max-width: 767px) {
  main {
    padding: 0 0 800px 0;
  }

  .footer h3 {
    font-size: var(--xmd-font) !important;
  }
}

@media only screen and (min-width: 767px) and (max-width: 989px) {
  .footer_wrapper {
    padding: 40px 40px 90px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1398px) {
  main {
    /* padding: 0 0 550px 0; */
  }

  .footer_wrapper {
    /* height: 550px; */
  }

}

@media only screen and (max-width: 1499px) {
  .student_slide {
    height: 270px;
  }

  .student_slide h2 {
    font-size: var(--xmd-font);
  }

  .offer_wrapper {
    margin: 20px 100px 0;
  }

}

@media only screen and (max-width: 1299px) {
  .offer_card-wrapper {
    grid-template-columns: repeat(auto-fill, minmax(23rem, 1fr));
    grid-gap: 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {

  /*-------------Update-css-start---------------*/

  .qm_boookmark {
    padding: 20px 10px;
  }

  /*-------------Update-css-end---------------*/
}

@media only screen and (min-width: 600px) and (max-width: 768px) {
  .user_l-desh .page_banner-data {
    width: 240px !important;
    margin: 0 0 0 auto !important;
    max-width: 240px !important;
  }

  .tw_c-right {
    grid-template-columns: repeat(auto-fill, minmax(22rem, 1fr));
  }

}

@media only screen and (max-width: 599px) {


  /*-------------update-start -css-start---------------*/
  .tw_c-right a {
    width: 75% !important;
  }

  .w_rank {
    max-width: 75% !important;
  }

  .w_img {
    width: 80px !important;
    height: 80px !important;
  }

  .w_1 .w_img {
    max-width: 130px !important;
    height: 130px !important;
    width: 130px !important;
  }

  .an_b-wrap {
    grid-template-columns: repeat(auto-fill, minmax(19rem, 1fr)) !important;
  }

  .an_br span {
    font-size: var(--xss-font);
  }

  .t_s-card-footer a {
    padding: 8px 20px;
    font-size: var(--sm2-font);
  }

  .t_w-card-box small {
    font-size: var(--ss-font);
    white-space: nowrap;
  }

  .t_w-card-inner {
    grid-template-columns: repeat(auto-fit, minmax(266px, 1fr)) !important;
    grid-gap: 1rem !important;
  }

  .sw_h-left button {
    margin-right: 10px !important;
  }

  .user_l-desh .user_name h4 {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 7ch;
  }

  .box-data {
    padding: 5px;
  }

  .user_l-desh .page_banner-data {
    width: 200px !important;
    max-width: 200px !important;
  }

  .t_s-board {
    display: grid;
    max-width: 100%;
  }

  .t_s-card {
    padding: 15px;
    margin-bottom: 15px;
  }

  .t_s-card span {
    font-size: var(--ss-font);
  }

  .t_s-board .scrore_img {
    position: unset !important;
    margin: 0 auto;
  }

  .t_s-board>div {
    border-bottom: 2px solid var(--l-gray);
    border-right: none;
    max-width: 80%;
    height: 100px;
    width: 80%;
  }

  .t_s-card-header p,
  .t_s-card-header span {
    font-size: var(--ss-font);
  }

  .t_s-card-header .r_date {}

  .topic_w-wrapper {
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr)) !important;
  }

  .signup_wrap {
    height: 100%;
  }

  .signup_top {
    padding: 30px 20px;
  }

  .signup_body .er_box span {
    font-size: 14px;
  }

  .signup_body input {
    max-width: 100% !important;
    padding: 10px 15px;
  }

  .target-exam h3,
  .exam_catagory h3 {
    font-size: var(--smm-font);
  }

  .get_sub h4 {
    font-size: var(--smm-font);
  }

  .get_sub p {
    font-size: var(--sm2-font);
  }

  .offer_box .get_sub-data {
    font-size: var(--sm2-font);
  }

  .topic_box .accordion-button {
    display: block !important;
  }

  .accordion-button .n_text img {
    width: 25px;
    max-width: 25px;
    right: -30px;
    bottom: 0;
  }

  .accordion-button .n_text {
    width: 25px;
    padding: 0 !important;
    height: 25px;
    min-width: 25px;
  }

  .bar_progress h5 {
    font-size: var(--sm-font);
    margin-bottom: 5px;
  }

  .bar_progress {
    margin-bottom: 10px !important;
  }

  .bar_progress h4 {
    font-size: var(--sm-font);
  }

  .an_chart h3 {
    font-size: 16px;
    margin: 0;
  }

  .y_box {
    padding: 10px;
  }

  .y_box .la-ellipsis-v {
    font-size: var(--md-font);
  }

  .filter-item img {
    max-width: 35px;
    padding: 3px;
    height: 35px !important;
  }

  .y_exam-box {
    grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr));
  }

  .y_box-direct h5 {
    font-size: var(--sm2-font);
  }

  .offer {
    margin-bottom: 20px;
  }

  .y_exam-heading h3 {
    font-size: var(--sm-font);
  }

  .offer h3 {
    font-size: var(--sm-font);
  }

  .offer_box h3 {
    font-size: var(--ss-font) !important;
  }

  .y_box-direct {
    margin-top: 5px;
  }

  .user_l-desh .user_profile {
    /* width: 100px !important; */
    margin-right: 15px;
  }

  .student_slide {
    height: 170px !important;
    margin: 0;
    padding: 0;
  }

  .ss_wrap {
    padding: 0;
  }

  .page_baneer .slick-active {
    margin: 0 !important;
    padding: 10px 0 0;
  }

  .user_l-desh .banner-data-box {
    grid-template-columns: repeat(auto-fill, minmax(50px, 1fr)) !important;
  }

  /*-------------update-start -css-end---------------*/

  /*-------------Experts Section start -css-start---------------*/

  .experts_right .sharp {

    right: -77px;
    top: -102px;
    width: 30%;
  }

  /*-------------Experts Section start -css-end---------------*/

  /*-------------Main Banner-css-start---------------*/
  .user_l-desh .page_banner-data {
    margin: 0px 0 0 auto !important;
  }

  .page_baneer p {
    padding: 10px !important;
  }

  .page_baneer .box-data p {
    padding: 0 0 10px 0 !important;
    text-align: left !important;
    font-size: var(--ss-font) !important;
  }

  .banner-two {
    width: 100% !important;
    padding: 200px 0 !important;
  }

  .women-banner-wrapper .alert-box {
    right: -29px;
    padding: 7px;
    top: -5%;
  }

  .active-student {
    top: -44%;
  }

  .congrat {
    right: 100px;
  }

  /*-------------Main Banner-css-end---------------*/



  /*-------------s plan-css-start---------------*/


  .p_yq {
    /* display: block; */
  }

  .s_plan-wrapper {
    margin: 30px 15px !important;
  }

  .s_plan {
    grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr));
  }

  .p_yq-right .dropdown {
    margin: auto 0 auto auto;
  }

  .s_plan .plan-card .review-bar {
    display: block;
    padding-top: 5px;
  }

  .s_plan .sub_box {
    margin-top: 10px;
    /* margin-bottom: 10px; */
  }

  .p_yq-right .dropdown button {
    text-align: right;
    padding: 0;
  }

  /*-------------s plan-css-end---------------*/

  /*-------------Subscription-plan start-css-strat---------------*/

  .sub_card-l {
    border-radius: 15px;
    padding: 15px;
  }

  .sub_card-l img {
    margin: auto 15px auto 0;
  }

  .bill_box .rs_bar h4,
  .bill_box .rs_bar p,
  .sub_card p,
  .sub_card span {
    font-size: var(--ss-font) !important;
  }

  .sub_card h4 {
    font-size: var(--md-font) !important;
  }

  .sub_card .gear_sub {
    max-width: 140px;
  }

  .discount_bar {
    padding: 10px;
  }

  .max_dis {
    margin-top: 5px;
  }

  .discount_bar .input-group button {
    padding: 10px 20px !important;
  }

  .max_dis span {
    font-size: var(--sm2-font) !important;
  }

  .bill_box .total_bar p,
  .bill_box .total_bar h3 {
    font-size: var(--sm-font) !important;
  }

  /*-------------Subscription-plan start-css-end---------------*/


  /*-------------offers-card start start-css-start---------------*/

  .offer_card-wrapper {
    grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  }

  .offer_card {
    width: 100%;
    display: block;
    padding: 15px 0 !important;
    margin: 15px auto !important;
    outline: 8px solid #dbdbdb;
  }

  .offers_card-left h1 {
    font-size: 40px !important;
    text-align: center;
    line-height: 40px !important;
  }

  .offers_card-right {
    text-align: center;
    padding: 0 10px;
  }

  .offers_card-right h4 {
    font-size: var(--md-font) !important;
  }

  .offers_card-right a {
    margin-top: 0;
  }

  .offer_lr,
  .offer_rr {
    width: 60px;
    height: 60px;
  }

  .offer_rr {
    right: -75px;
  }

  .offer_lr {
    left: -15px;
  }

  .offers_card-right button {
    padding: 5px 20px;
  }

  /*-------------offers-card start start-css-end---------------*/


  /*-------------Bill Info-css-start---------------*/

  .bill_info-wrapper {
    margin: -25px 20px 30px !important;
  }

  .bill_info {
    padding: 20px 15px !important;
    border-radius: 15px;
  }

  .page_baneer.bill_banner {
    /* padding: 30px 15px 50px !important; */
  }

  .user_contact h3 {
    font-size: var(--sm-font) !important;
    margin: auto 0 auto 10px !important;
  }

  .user_co-l {
    width: unset !important;
  }

  /*-------------Bill Info-css-end---------------*/


  /*-------------Congrats-info-css-start---------------*/

  .congrets_box {
    max-width: 100% !important;
    margin: 0 15px;
  }

  .congrets_box h1 {
    font-size: var(--xxl-font) !important;
  }

  .congrets_box p {
    margin-bottom: 15px;
  }

  .congrets_box h2 {
    font-size: var(--md-font) !important;
  }

  /*-------------Congrats-info-css-end---------------*/

  /*-------------My-order-css-start---------------*/

  .my_order-wrapper .sub_card {
    display: block !important;
  }

  .my_order .sub_card-l {
    width: 100% !important;
  }

  .my_order .dow_invoice-wrapper {
    width: 100% !important;
    flex-direction: row !important;
    /* padding: 0 0 20px 20px; */
    justify-content: space-between !important;
  }

  /*-------------My-order-css-end---------------*/
  /*-------------Experience Advanced Section start---------------*/

  .topic_box .tab-content {
    padding: 10px;
    margin-bottom: 10px;
  }

  .topic_box .nav-tabs .nav-link {
    padding: 8px 0;
  }

  .topic_box .nav-tabs .nav-link {
    font-size: var(--ss-font) !important;
  }

  .topic_box .plan-card h4 {
    margin-top: 0 !important;
  }

  .topic_wrapper {
    box-shadow: none;
  }

  /*-------------Experience Advanced Section End---------------*/


  /*-------------Offer Exams-css-start---------------*/

  .y_exam-box {
    grid-gap: 0.5rem !important;
  }

  .n_update-bar {
    margin: 25px 0 40px;
  }

  .n_update h1 {
    font-size: var(--md-font) !important;
  }

  /*-------------Offer Exams-css-end---------------*/

  /*-------------chapter-css-start---------------*/

  .cp_box {
    grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr)) !important;
  }

  /*-------------chapter-css-end---------------*/


}

@media only screen and (min-width: 767px) {

  .other_tw-card .img_won {
    max-width: 80px !important;
    height: 80px !important;
    margin: -10px 10px !important;
    width: 80px !important;
  }
}

@media only screen and (max-width: 767px) {
  .w_no span:nth-child(3) {
    font-size: var(--sm2-font);
  }

  .top-header {
    padding: 5px 0;
  }

  .top_win {
    /* padding: 20px 20px 60px; */
  }

  .bill_info-wrapper {
    margin: 20px !important;
  }

  .bo_an_b-wrap {
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr)) !important;
  }

  .other_tw-card .score_card {
    margin-top: 15px !important;
  }

  .t_w-card-box .half_score {
    font-size: var(--ss-font) !important;
    margin: 4px 0 0 3px;
  }

  .t_w-card-box {
    padding: 0;
  }

  .other_tw-card .img_won {
    max-width: 70px !important;
    width: 70px !important;
    height: 70px !important;
    margin: -9px 10px !important;
  }

  .user_won-bar span {
    overflow: hidden;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 8ch;
  }

  .data_table tbody tr {
    margin: 5px 0;
    box-shadow: none;

  }

  .topic_box .data_table table tr td {
    font-size: var(--sm2-font);
  }

  .data_table tbody tr td {
    padding: 5px;
  }

  .topic_box .data_table th {
    font-size: var(--sm2-font);
  }

  .user_won-bar .img_won {
    max-width: 90px;
    width: 90px;
    height: 90px;
    margin: -18px 10px;
  }

  .user_won-bar button {
    width: 35px;
    height: 35px;
  }

  .t_w-card-inner {
    margin-top: 25px;
  }

  .t_w-card-box p {
    font-size: var(--smm-font);
  }

  .card_status .crt_data,
  .card_status .ict_data,
  .card_status .utm_data {
    font-size: var(--ss-font) !important;
    /* text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 8ch; */

  }

  .score_card {
    padding: 0 !important;
  }

  .t_w-card-box .score_c-gr {
    width: 95px;
    height: 95px;
  }

  .t_w-card {
    padding: 10px;
  }

  .t_w-card h2 {
    font-size: var(--md-font);
  }

  .comperative_header img {
    max-width: 60px;
    width: 60px;
    margin-right: 10px;
  }

  .user_marks span {
    font-size: var(--ss-font);
    margin: auto 0 auto 5px;
  }

  .user_marks h4 {
    font-size: var(--sm-font);
  }

  .final-result .p_yq-left button {
    right: -8px;
    width: 35px;
    height: 35px;
  }

  .p_yq-right h1 {
    font-size: var(--md-font);
  }

  .r_score-wrap {
    max-width: 100px;
    height: 100px;
  }

  .t_s-board>div {
    height: 120px;
  }

  .t_s-board h2 {
    font-size: var(--xl-font);
  }

  .t_s-board p {
    font-size: var(--sm2-font);
  }

  .t_s-board .scrore_img {
    max-width: 140px;
  }

  .sw_a-header {
    margin: 10px 0;
  }

  .box-data h5 {
    font-size: var(--sm2-font) !important;
  }

  .user_l-desh .user_name h4 {
    font-size: 14px !important;
  }

  .user_l-desh .user_name-inner button {
    font-size: 14px !important;
  }

  .user_l-desh .user_profile {
    width: 60px !important;
    height: 60px !important;
    margin-right: 5px !important;
  }

  /*-------------Update-css-start---------------*/
  .y_exam-heading h3 {
    margin-bottom: 10px;
    /* font-weight: 300; */
  }

  .page_baneer .slick-prev,
  .page_baneer .slick-next {
    width: 30px;
    height: 30px;

  }

  .page_baneer .slick-prev {
    left: -10px !important;
  }

  .page_baneer .slick-next {
    right: -10px !important;
  }

  .student_slide h2 {
    font-size: var(--sm-font);
  }

  .sw_card {
    height: 280px;
    padding: 20px;
  }

  .sw_card h4 {
    font-size: var(--xl-font);
  }

  .sub_w-wrapper {
    margin: 50px 20px;
  }

  .topic_w-wrapper {
    margin: 30px 20px;
  }

  .qm_timer {
    width: 90px;
  }

  .q_book-list {
    padding: 15px 0 !important;
  }

  .at-btn .ay_time span,
  .yt-btn .ay_time span {
    font-size: var(--ss-font) !important;
    line-height: 15px;
  }

  .ay_time-wrap img {
    max-width: 20px !important;

    margin: auto 5px auto 0;
  }

  .ay_time-wrap button {
    padding: 6px 8px;
    margin: 0 5px !important;
  }

  .com_sol-box h3 {
    font-size: var(--md-font);
  }

  .com_sol-box-inner p {
    font-size: var(--xmd-font);
    overflow-x: auto;
    overflow-y: hidden;
  }

  .bookmark {
    width: 15px !important;
    height: 20px !important;
  }

  .bookmark__stroke::before {
    bottom: -80% !important;
  }

  .y_exam-box {
    grid-gap: 1rem;
  }

  .user_l-desh .banner-data-box {
    grid-template-columns: repeat(auto-fill, minmax(4rem, 1fr));
    grid-gap: 0.5rem !important;
  }

  .user_l-desh .page_banner-user {
    /* width: 100% !important; */
  }

  .user_l-desh .banner-data-box .box-img {
    width: 40%;
  }

  .user_l-desh .box-data p {
    margin: 0 auto 20px;
    padding: 0 0px 15px 0px;
  }

  .user_l-desh .page_banner-data p {
    margin-left: auto;
    font-size: var(--sm2-font);
    width: 100%;
    text-align: left;
    margin-bottom: 1px;
    padding: 0 0 10px 0;
  }

  .bill_info .round label:after {
    top: 6px;
    left: 6px;
  }

  .get_sub-wrap {
    padding: 20px !important;
  }

  .offer_box h3 {
    font-size: var(--sm2-font);
  }

  .get_sub-box {
    padding: 10px;
  }

  .get_month p {
    width: 25px;
    height: 25px;
  }

  .get_month p span {
    font-size: 14px;
  }

  .get_month small {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 10ch;
  }

  .get_sub-box .final_p,
  .get_sub-box .offer_p {
    font-size: 16px;
  }

  .bar_fider {
    padding: 12px;
  }

  .bar_line span {
    font-size: var(--sm2-font);
    font-weight: 600;
  }

  .bar_progress h4 {
    left: 9px;
    top: 2px;
  }

  .cp_mr {
    grid-gap: 10px;
  }

  .cp_r {
    padding: 15px 10px;
  }

  .cp_mr img {
    max-width: 50px;
  }

  .cp_card {
    display: block;
    border-radius: 10px;
  }

  .cp_l {
    width: 100%;
    max-width: 100%;
    border-radius: 10px;
  }

  .cp_l-inner {
    margin: 10px auto;
    display: flex;
    align-items: center;
  }

  .cp_l-inner p {
    margin: 0;
  }

  .cp_l-inner h1 {
    font-size: 40px;
    margin: 0 10px;
  }

  .s_plan .plan-card h4 {
    /* height: 45px; */
    margin-top: 10px;
    margin-bottom: 0;
  }

  .qm_wrapper {
    /* margin: 0 !important; */

  }

  .qm_b-icon i {
    font-size: var(--xl-font);
  }

  .qm_board {
    padding: 0px 10px 20px !important;
    margin: 0 !important;
  }

  .qm_solve i {
    font-size: var(--md-font);
  }

  .qm_solve p {
    font-size: var(--ss-font);
  }

  .qm_timer span {
    font-size: var(--ss-font);
  }

  .qm_timer i {
    font-size: var(--ss2-font);
  }

  .qm_n {
    width: 25px;
    height: 25px;
  }

  .qm_n span {
    font-size: var(--xss-font);
  }

  .qm_bar p {
    font-size: var(--sm2-font);
  }

  .an_b-n {
    padding: 5px;
    /* margin-bottom: 10px; */
  }

  .an_count span {
    font-size: var(--sm2-font);
    font-weight: 200;
  }

  .an_count {
    width: 25px;
    height: 25px;
    min-width: 25px;
  }

  .an_dummy span {
    font-size: var(--sm2-font);
  }

  .nav_b {
    margin-top: 15px;
  }

  .nav_b .pr_btn,
  .nav_b .ne_btn,
  .nav_b .mr_btn {
    max-width: 30%;
    width: 30%;
    font-size: var(--sm2-font);
  }

  .qm_boookmark {
    /* padding: 15px 0px !important; */
  }

  .qm_boookmark .nav-tabs .nav-link {
    padding: 0 0 10px;
    font-size: var(--sm-font);
  }

  .qm_boookmark .nav-tabs .nav-link:hover::after,
  .qm_boookmark .nav-tabs .nav-link.active::after {
    bottom: 0;
  }

  .bs_bar p {
    font-size: var(--sm2-font);
  }

  .sub_card-l {
    padding: 20px 10px 0 10px;
  }

  .p_checkout button a {
    max-width: 100%;
    padding: 10px 45px;
    width: 100%;
    font-size: var(--sm2-font);
  }

  .max_dis img {
    width: 20%;
    max-width: 50%;
  }

  .sub_card .close i {
    font-size: var(--smm-font);
  }

  .sub_card .close button {
    width: 34px;
    height: 34px;
  }

  .bill_box .rs_bar,
  .bill_box .total_bar {
    margin-bottom: 10px;
  }

  .bill_info .round label {
    margin: 0;
    width: 23px;
    height: 23px;
  }

  .user_bill button {
    font-size: var(--xl-font);
    padding: 0;
  }

  /*-------------Update-css-end---------------*/

  /*-------------top-header-css-start---------------*/
  .login_screen .login img {
    max-width: 200px;
  }

  .login_screen {
    height: 100%;
  }

  .mobi_data-wrap span.text-danger {
    font-size: 14px;
  }

  .login_screen .login {
    padding: 100px 20px;
  }

  .login button {
    margin-top: 25px;
  }

  .navbar .nav-link {
    text-align: center;
  }

  .top-finder {
    display: block !important;

  }

  .search-bar {
    width: 100%;
    margin-bottom: 20px;
  }

  .cls-box {
    justify-content: center;
  }

  /*-------------top-header-css-start---------------*/

  /*-------------Footer-css-start---------------*/

  .footer p {
    font-size: var(--sm2-font);
  }

  .footer_wrapper {
    border-radius: 30px 30px 0 0;
    padding: 20px 15px 70px !important;
  }

  .footer_link {
    margin: unset;
  }

  /*-------------Footer-css-end---------------*/

  /*-------------Rg section-css-start---------------*/
  .rg-bar-wrapper {
    max-width: 90%;
  }

  /*-------------Rg section-css-end---------------*/

  /*-------------Right-for-you-Section-css-start---------------*/
  #right-fy {
    padding: 70px 20px 50px 20px !important;


  }

  .rg-bar h1 {
    font-size: var(--xl-font);
  }

  /*-------------Right-for-you-Section-css-end---------------*/

  /*-------------Counter-board-Section-css-start---------------*/
  .counter_right {
    display: inline-grid;
  }

  #counter {
    padding: 60px 30px !important;
  }

  .counter_right-inner {
    width: 100%;
  }

  /*-------------Counter-board-Section-css-end---------------*/

  /*-------------Main Banner-css-start---------------*/

  .banner-one {
    padding: 50px 20px !important;
  }

  .banner-one h1 {
    font-size: 30px !important;
  }

  .banner-one p {
    font-size: var(--sm-font) !important;
  }

  .dl-btn .dark-btn {
    margin-right: 10px;
    padding: 8px 20px;
  }

  .light-btn {
    padding: 8px 20px;
  }

  /*-------------Main Banner-css-end---------------*/



  /*-------------App Banner Section Start---------------*/

  .app-left {
    padding: 0 20px 40px !important;
  }

  .app-left_logo {
    margin-bottom: 25px;
    max-width: 60%;
  }

  #app-banner {
    padding: 80px 0;
    overflow: hidden;
  }

  .app-left_arrow {
    margin: -27px 0 5px 0 !important;
    width: 40%;
  }

  .watch-shap {
    bottom: 0;
  }

  .r-b-orange {
    right: -53%;
    top: -19%;
  }

  /*-------------App Banner Section end---------------*/

  /*-------------Trending Section End start---------------*/

  .sec-header h3 {
    font-size: 28px;
    font-weight: 600;
  }

  .sec-header p {
    font-size: var(--sm-font);
  }

  .sec-header {
    padding-bottom: 20px;
  }

  .trending-plans {
    max-width: 92%;
  }

  .plan-card h4 {
    font-size: var(--xmd-font);
  }

  .plan-card .gear_bg {
    right: -76px;
    top: -70px;
  }

  .plan-card p {
    font-size: var(--sm2-font) !important;
  }

  .plan-card .card_status {

    right: 18px;
    top: 25px;
  }

  .plan-card .card_status-box h3 {
    font-size: 24px;
    text-align: center;
  }

  .plan-card .review-bar {
    padding-top: 15px;
  }

  #trending-plans {
    padding: 30px 0 50px;
  }

  /*-------------Trending Section End end---------------*/

  /*-------------Experts Section start -css-start---------------*/

  .experts_box {
    max-width: 90% !important;
  }

  .experts_box h1 {
    font-size: var(--xxl-font);
    margin-bottom: 20px !important;
  }

  .experts_box p {
    font-size: var(--sm-font) !important;
    margin-bottom: 40px !important;
  }

  .experts .dark-btn h4 {
    font-size: var(--sm-font) !important;
  }

  .experts_right-inner {
    max-width: 100%;
    margin: 30px 30px -90px 30px !important;
  }

  .experts_card {
    padding: 15px;
    height: 220px;
  }

  .experts_card p {
    font-size: var(--sm2-font);
  }

  /*-------------Experts Section start -css-end---------------*/

  /*-------------Experience Advanced Section start---------------*/
  .ex-advanced .sec-title {
    font-size: var(--xxl-font);
  }

  #ex-advanced {
    padding: 180px 20px 30px !important;
  }

  .wave-shap {
    width: 30%;
    top: 70px !important;
  }

  .ex-click {
    max-width: 100%;
    padding: 15px !important;
    margin-bottom: 20px;
  }

  .ex-click p {
    font-size: 14px;
    padding-left: 10px;
  }

  .ex-click img {
    max-width: 20%;
    width: 10%;
    /* display: flex; */
    height: fit-content;
    /* margin-top: 10px; */
  }

  /*-------------Experience Advanced Section End---------------*/

  /*-------------Bloge Section start -css-start---------------*/

  #bloge {
    display: block;
    padding: 80px 0 80px 20px !important;
  }

  .bloge_details {
    width: 98% !important;
    margin-bottom: 30px;
    margin-top: 0;
  }

  .bloge_slider {
    width: 100% !important;
  }

  #bloge h1 {
    font-size: var(--xxl-font);
  }

  #bloge p {
    font-size: var(--sm-font);
  }

  .sharp {
    display: none;
  }

  .blog-title h5 {
    font-size: var(--sm-font);
  }

  .bloge-box {
    margin: 15px;
  }

  .blog-date {
    width: 50px;
    height: 50px;
  }

  .blog-date>div {
    margin: 12px auto;
  }

  /*-------------Bloge Section start -css-end---------------*/

  /*-------------Students Reviews Section strat---------------*/

  .review-box {
    width: 100% !important;
  }

  .review-heading {
    max-width: 90%;
    padding: 0px 0 0 30px !important;
  }

  .review-box .app-left_arrow {
    width: 20% !important;
    bottom: -40px !important;
  }

  .review-heading h1 {
    font-size: var(--xxl-font);
  }

  #students-review .br-shap {
    display: none;
  }

  .review-heading p {
    font-size: var(--sm-font);
  }

  .review-slider {
    display: block;
    margin: 57px 0px;
    border: 1px solid #f881255e;
    box-shadow: none;
  }

  .review-slider p {
    margin: 10px 0 15px !important;
  }

  .review-box .owl-carousel {
    margin: 30px -90px 30px 20px !important;
  }

  /*-------------Students Reviews Section end---------------*/


  /*-------------Target Exams-css-start---------------*/

  .page_baneer {
    padding: 10px;
  }

  .page_baneer.bill_banner {
    padding: 10px !important;
  }

  .page_baneer p {
    padding: 15px;
  }

  .page_baneer h3,
  .page_baneer .ae_heading {
    font-size: var(--sm-font) !important;
    text-align: left;
  }

  .find-bar {
    width: 100%;
    max-width: 1005;
    margin: 0;
    padding: 30px 15px;
  }

  .card_left img {
    max-width: 60px;
  }

  .exam_catagory .card_left p {
    font-size: var(--sm-font) !important;
    margin: auto auto auto 10px;
  }

  .exam_select button {
    padding: 10px 0;
    max-width: 120px;
    width: 120px;
  }

  .exam_select {
    padding: 15px 15px 15px 30px !important;
    border-radius: 40px;
    margin: 60px 0px -40px;
  }

  .exam_select h3 {
    line-height: 46px;
    font-size: var(--xmd-font) !important;
  }

  .card_right {
    margin: 0;
  }

  .card_right-inner {
    max-width: 50%;
  }

  .exam_card {
    padding: 0;
  }

  /*-------------Target Exams-css-end---------------*/



  /*-------------s plan-css-start---------------*/



  .p_yq-left h1 {
    font-size: var(--md-font);
    margin: auto auto auto 20px;
  }

  .p_yq-left img {
    max-width: 40px !important;
    height: 40px !important;
    transition: 0.8s;
    transition-timing-function: ease-out;
  }

  .p_yq-right button {
    font-size: 16px;
  }

  .s_plan .plan-card .gear_bg {

    right: -46px;
    top: -44px;

  }

  .co_heading {
    font-size: var(--md-font);
    margin-bottom: 20px !important;
  }

  /*-------------s plan-css-end---------------*/

  /*-------------Subscription-plan start-css-strat---------------*/

  .sub_plan-board {
    margin: 15px 20px !important;
  }

  .sub_card_w-inner .sub_card {
    display: flex !important;
    border-radius: 10px !important;
    margin-bottom: 0 !important;
  }

  .sub_card_w-inner {
    grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr));
  }

  .list_sc-wrapper {
    grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr)) !important;
  }

  .sub_card_w-inner .sub_card-l {
    padding: 5px 0 5px 5px !important;
  }

  .sub_card_w-inner .sub_card-r {
    padding: 15px 0 15px 10px;
  }

  .sub_card_w-inner .sub_card .close {
    padding: 0 10px !important;
  }

  .sub_card_w-inner .sub_card-l img {
    max-width: 50px;
    margin: auto 10px auto 0;
  }

  .sub_card_w-inner .sub_card p {
    font-size: var(--ss-font) !important;
    white-space: nowrap;
  }

  .sub_card_w-inner .sub_card .close button {
    width: 25px;
    height: 25px;
    padding: 0;
  }

  .sub_card_w-inner .sub_card h4 {
    font-size: var(--ss-font) !important;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .sub_card_w-inner .sub_card span {
    font-size: var(--ss-font) !important;
    -webkit-line-clamp: 1;
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .sub_card {
    display: block;
    margin-bottom: 25px !important;
  }

  .sub_card-r {
    padding: 15px;
  }

  .sub_card-r p {
    margin: auto 0 auto auto;
  }

  .sub_card .close {
    left: 94%;
    top: 2%;
  }

  .sub_card .close a {
    width: 40px;
    height: 40px;
  }

  .add_sub-wrapper {
    margin: 40px 0;
  }

  .discount_bar .input-group button {
    font-size: var(--sm-font);
  }

  .discount_bar input::placeholder,
  .discount_bar input {
    font-size: var(--sm-font) !important;
    font-weight: 100;
    padding: 0 20px !important;
  }

  .discount_bar input::placeholder {
    padding: 0 !important;
  }

  .bill_box-wrapper {
    border-radius: 15px;
    padding: 15px;
    border: 5px solid #fa600a2b;
    margin: 15px 0 !important;
  }

  .p_checkout button {
    padding: 10px 0 !important;
  }

  /*-------------Subscription-plan start-css-end---------------*/

  /*-------------offers-card start start-css-start---------------*/

  .offer_card-wrapper {
    margin: 40px 10px !important;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr)) !important;
  }

  .my_order-wrapper .sub_card-r p:nth-child(2) {
    font-size: var(--ss-font) !important;
  }


  /*-------------offers-card start start-css-end---------------*/


  /*-------------Bill Info-css-start---------------*/

  .user_bill h1 {
    font-size: var(--xmd-font);
    margin: 0;
  }

  .user_bill a {
    font-size: var(--xxl-font);
  }

  .user_co-l i {
    font-size: var(--xmd-font);
  }

  .user_co-l {
    /* width: 50px;
  height: 50px; */
  }

  .bill_info {
    padding: 20px 15px;
    border-radius: 15px;
    margin: auto auto 15px auto;
  }

  /*-------------Bill Info-css-end---------------*/

  /*-------------Congrats-info-css-start---------------*/

  .congrets_box img {
    left: 50%;
    top: 0%;
  }

  .congrets_box {
    padding: 100px 0 40px;
  }

  /*-------------Congrats-info-css-end---------------*/


  /*-------------My-order-css-start---------------*/

  .my_order-wrapper {
    margin: 30px 15px !important;
  }

  .my_order .co_heading {
    margin-bottom: 30px;
  }

  .my_order-wrapper .sub_card {
    display: flex;
  }

  /*-------------My-order-css-end---------------*/

  /*-------------Topic-css-start---------------*/

  .bar_progress-wrapper {
    padding: 15px;
  }

  .accordion-button {
    padding: 10px 0;
  }

  .accordion-button .ah_head p {
    font-size: var(--sm2-font);
    line-height: 1.5;
  }

  .accordion-button .n_text {
    font-size: var(--sm2-font);
    padding: 5px 10px 2px;
    margin: 0 15px auto 0;
  }

  .accordion-button .la-caret-down {
    padding: 4px;
    right: 0;
    bottom: 10px;
    font-size: var(--sm-font);
  }

  .accordion-body p {
    margin-top: 0;
    padding: 15px;
    font-size: var(--sm2-font);
  }

  .accordion-body {
    padding: 0 0 15px;
  }

  .ah_bottom h6 {
    font-size: var(--ss-font);
  }

  .ah_bottom span {
    font-size: var(--sm2-font);
  }

  /*-------------Topic-css-end---------------*/
  /*-------------side bar css start---------------*/

  .side_bar {
    top: unset;
    bottom: 0;
    left: 0;
    background-color: var(--d-white);
    z-index: 9999;
    box-shadow: 0 0 0px 3px #f88125;
    border-radius: 0 0;
  }

  .side_bar ul {
    display: flex;
    padding: 10px 5px;
  }

  .side_bar ul li {
    flex: 1;
    height: auto;
    width: auto;
  }

  .side_bar a i {
    font-size: var(--xxl-font);
  }

  .side_bar span {
    color: var(--d-gray);
  }

  /* .side_bar span{
      display: none;
    }
    .side_bar .active span, .side_bar li a:hover span{
      display: block;
    } */

  /*-------------side bar css End---------------*/


  /*-------------Offer Exams-css-start---------------*/

  .offer_wrapper {
    margin: 20px 15px 0 !important;
  }

  .offer {
    padding: 0 10px !important;
    box-shadow: none;
    border: none;
    border-radius: 0;

  }

  .offer_box {
    padding: 5px 3px;
    margin-top: 10px
  }

  .offer_box img {
    margin-right: 6px;
    max-width: 25%;
    height: auto;
  }

  /* .offer_box.active {
    box-shadow: 0 0 0 2px var(--l-blue);
} */
  .offer h3 {
    margin-bottom: 0px;
    /* font-weight: 300; */
  }

  .y_exam-box {
    grid-gap: 1rem;
  }

  .y_box {
    /* margin: 8px 0; */
    padding: 15px 10px;
  }

  .n_update h1 {
    font-size: var(--xl-font);
  }

  .n_update img {
    max-width: 40px;
    height: fit-content;
  }

  .n_update a {
    height: 40px;
    width: 40px;
  }

  .y_exam-heading {
    margin-bottom: 0 !important;
  }

  /*-------------Offer Exams-css-end---------------*/
  /*-------------chapter-css-start---------------*/

  .cp_box-wrapper {
    max-width: 100%;
    border-radius: 15px;
    margin: -45px auto 40px;
  }

  .cp_rm p {
    font-size: var(--sm-font);
  }

  .cp_rm span,
  .cp_ml span {
    font-size: var(--sm2-font);
  }

  .cp_box-wrapper {
    padding: 10px;
    margin: 30px 20px !important;
  }

  .cp_box {
    grid-gap: 1rem;
  }

  /*-------------chapter-css-end---------------*


/*-------------sidebar-login-css-start---------------*/

  .sidebar_top {
    padding: 25px;
  }

  .sidebar_top h1 {
    font-size: var(--sb-font);
  }

  .sidebar_top .cr_account {
    font-size: var(--md-font);
  }

  .sidebar_top .input-group {
    max-width: 100%;
  }

  .sidebar_top input {
    font-size: var(--md-font);
  }

  .sidebar_bottom p {
    font-size: var(--sm-font);
  }

  .sidebar_top .sub_btn {
    padding: 10px 50px;
  }

  /*-------------sidebar-login-css-end---------------*/


}



@media only screen and (max-width: 990px) {

  .discount_bar .input-group,
  .discount_bar {
    border-radius: 10px !important;
  }

  .list_sc-wrapper {
    grid-template-columns: repeat(auto-fill, minmax(28rem, 1fr));
  }

  .offer_lr,
  .offer_rr {
    /* width: 50px;
    height: 50px; */
  }

  #rg-bar {
    margin: 40px auto;
  }

  .student_slide {
    height: 220px;
  }

  .topic_w-wrapper {
    margin: 20px;
  }

  .tw_card {
    padding: 15px;
  }

  .page_banner-data {
    /* width: 45% !important;
    max-width: 45% !important; */
  }

  .main_profile .user_profile-main .edit_p-img {
    width: 30px;
    height: 30px;
  }

  .main_profile .user_profile-main .edit_p-img i {
    right: 6px !important;
    top: 8px !important;
    font-size: var(--sm-font);
  }

  .cp_box-wrapper {
    margin: 20px 15px !important;
  }

  .bs_bar-r,
  .bs_bar-w,
  .bs_bar-o,
  .bs_bar {
    margin: 0 6px 10px 0px;
  }

  .banner-data-box {
    /* grid-gap: 1rem; */
  }

  .user_l-desh .page_banner-user {
    width: auto;
  }

  .user_l-desh .user_name-inner button {
    font-size: var(--md-font);
  }

  .user_l-desh .user_profile {
    width: 90px;
    height: 90px;
    margin-right: 20px;
  }

  .user_l-desh .user_name h4 {
    font-size: 18px;
  }

  /* .user_l-desh .page_banner-user{
    width: 100% !important; 
  } */

  /*-------------top-header-css-start---------------*/
  .offer {
    padding: 25px;
  }

  .navbar .nav-link {
    padding: 15px 0;
  }

  .top-finder {
    display: flex;
    justify-content: space-between;
  }

  .edit_bill-info label {
    font-size: var(--sm-font);
    font-weight: 100;
  }

  .edit_bill-info input {
    padding: 10px 10px;
    font-weight: 200;
    font-size: var(--sm2-font);
  }

  /*-------------top-header-css-start---------------*/
  /*-------------Footer-css-start---------------*/



  .footer h3 {
    margin: 20px 0 10px;
    font-size: var(--md-font);
  }

  .footer_link a {
    font-size: var(--sm-font);
    margin-bottom: 0;
  }

  /*-------------Footer-css-end---------------*/

  /*-------------Rg section-css-start---------------*/
  .rg-bar {
    padding: 30px 20px !important;
  }

  #rg-bar .footer-img {
    display: none;
  }

  .rg-bar p {
    max-width: 100%;
  }

  /*-------------Rg section-css-end---------------*/

  /*-------------Right-for-you-Section-css-start---------------*/

  #right-fy {
    padding: 100px 50px 50px 50px;
    overflow: hidden;
  }

  /*-------------Right-for-you-Section-css-end---------------*/

  /*-------------Counter-board-Section-css-end---------------*/

  .counter_box {
    display: block;
  }

  .counter_left {
    width: 100%;
    border-right: none;
    border-bottom: 2px solid #8080803d;
    padding-bottom: 25px;
  }

  .counter_right-inner {
    display: unset;
    margin-top: 25px;
  }

  /*-------------Counter-board-Section-css-end---------------*/

  /*-------------Main Banner-css-start---------------*/

  .main-banner-wrapper {
    display: block;
  }

  .banner-one {
    width: 100%;
  }

  .banner-two {
    width: 80%;
    margin-left: auto;
    padding: 300px;
  }

  .banner-one {
    padding: 100px 60px 0 0;
  }

  /*-------------Main Banner-css-end---------------*/

  /*-------------App Banner Section Start---------------*/

  .app-left {
    padding: 0 50px 40px;
  }

  /*-------------App Banner Section end---------------*/

  /*-------------Experience Advanced Section start---------------*/

  #ex-advanced {
    padding: 300px 80px 80px;
  }

  #ex-advanced .watch2 {
    bottom: 0;
  }

  /*-------------Experience Advanced Section End---------------*/
  /*-------------Bloge Section start -css-start---------------*/

  #bloge {
    display: block;
    /* padding: 80px 0 80px 80px !important; */
  }

  .bloge_details {

    margin-bottom: 30px;
  }


  /*-------------Bloge Section start -css-end---------------*/

  /*-------------Students Reviews Section start---------------*/

  .review-wrapper {


    display: block;
  }

  .review-box {
    width: 85% !important;
  }

  .review-box .owl-nav {
    position: absolute;
    /* right: -190px; */
    left: 25px;
    right: unset;
    bottom: -3px;
  }

  #students-review .br-shap {
    display: none;
  }

  /*-------------Students Reviews Section end---------------*/
  /*-------------Target Exams-css-start---------------*/

  .page_banner-wrapper,
  .target-exam,
  .exam_catagory {
    padding: 0 0 !important;
  }

  .page_baneer p {
    font-size: var(--sm-font);
  }

  .page_baneer h3 {
    font-size: var(--md-font);
    margin: 0;

  }

  .exam_catagory .card_left p {
    font-size: var(--xmd-font);
  }

  .exam_select h3 {
    font-size: var(--xl-font);
  }

  .exam_select {
    padding: 25px 40px;
  }

  /*-------------Target Exams-css-end---------------*/

  /*-------------Subscription-plan start-css-strat---------------*/

  .max_dis span,
  .bill_box .total_bar p,
  .bill_box .total_bar h3,
  .add_sub h3 {
    font-size: var(--md-font);
  }

  .sub_plan-board {
    margin: 60px 80px;
  }

  /*-------------Subscription-plan start-css-end---------------*/


  /*-------------offers-card start start-css-strat---------------*/

  .offers_card-left h1 {
    font-size: var(--xxl-font) !important;
    line-height: 70px;
  }

  .offers_card-right h4 {
    font-size: var(--md-font);
    font-weight: 200;
  }

  .offer_card {
    padding: 60px 50px 30px;
  }

  .offer_card-wrapper {
    margin: 20px 20px !important;
    grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
  }

  /*-------------offers-card start start-css-end---------------*/

  /*-------------Bill Info-css-start---------------*/

  .bill_info-wrapper {
    /* margin: -90px 80px 70px; */
  }

  .user_contact h3 {
    font-size: var(--xmd-font);
  }

  .user_contact {
    margin-bottom: 0;
  }

  .user_bill {
    margin-bottom: 10px;
    align-items: center;
  }

  .con_address {
    margin-top: 20px;
  }

  .con_address button {
    font-size: var(--sm2-font);
  }

  /*-------------Bill Info-css-end---------------*/

  /*-------------Congrats-info-css-start---------------*/
  .congrets_box img {
    max-width: 200px !important;
  }

  /*-------------Congrats-info-css-end---------------*/

  /*-------------My-order-css-start---------------*/

  .my_order-wrapper {
    margin: 30px 15px;
  }

  .my_order .sub_card-l {
    /* padding: 20px; */
    width: 60%;
  }

  .my_order .sub_card-r {
    padding: 10px 10px 10px 0;
  }

  .dow_invoice img {
    display: none;
  }

  .dow_invoice {
    padding: 5px 10px 5px 0;
  }

  .my_order .dow_invoice-wrapper {
    width: 40%;
  }

  .my_order .sub_card h4 {
    font-size: var(--md-font);
  }

  .dow_invoice button {
    padding: 8px 15px;
  }

  .my_order .sub_card p {
    font-size: var(--sm-font);
  }

  .dow_invoice button a {
    font-size: var(--sm2-font);
  }

  /*-------------My-order-css-end---------------*/
  /*-------------Experience Advanced Section start---------------*/

  .topic_wrapper {
    margin: 0 !important;
  }

  .topic_box .nav-tabs .nav-link {
    /* font-size: var(--md-font); */
  }

  /*-------------Experience Advanced Section End---------------*/


  /*-------------Offer Exams-css-start---------------*/

  .offer_wrapper {
    margin: 20px 15px 0;
  }

  /*-------------Offer Exams-css-end---------------*/

  /*-------------chapter-css-start---------------*/

  .page_baneer.bill_banner {
    /* padding: 40px 50px 120px; */
  }

  /*-------------chapter-css-end---------------*/

}

@media only screen and (min-width: 768px) {
  .side_bar li:nth-child(3) {
    display: none;
  }
}

@media only screen and (max-width: 989px) {

  /*-------------updates-css-start---------------*/
  .top-header {
    padding: 5px 0;
  }

  .navbar-collapse {
    position: absolute;
    background: rgb(255, 255, 255);
    width: 100%;
    top: 58px;
    left: 0px;
    box-shadow: rgb(248 129 37 / 63%) 0px 20px 22px -9px;
    padding: 0px 15px 20px;
  }

  .result_c-wrap {
    margin: 20px 20px !important;
  }

  .sw_a-wrap {
    margin: 10px 15px 40px !important;
  }

  .pq_qm-wrapper {
    margin: 20px 15px 30px !important;
  }

  .qm_wrapper {
    display: block;
    margin: 20px 15px 30px;
  }

  .qm_board {
    border-radius: 15px;
  }

  .qm_board-wrap {
    width: 100%;
    border-radius: 15px;
  }

  .qm_boookmark {
    width: 100%;
    border-radius: 15px;
    margin: 20px 0 0;
  }

  .sub_plan-board {
    margin: 20px;
  }

  .sub_card h4 {
    font-size: var(--md-font);
  }

  .bill_info-wrapper {
    /* margin: -50px 80px 0px  ; */
  }

  .s_plan-wrapper {
    margin: 20px 15px !important;
  }

  .co_heading {
    margin-bottom: 30px;
  }

  /* .page_baneer.bill_banner{
    padding: 40px 50px 80px;
  } */
  /*-------------updates-css-end---------------*/
}

@media only screen and (max-width: 1360px) {
  .page_banner-data {
    /* width: 35%;
      max-width: 35%; */
  }
}

@media only screen and (min-width: 1199px) {

  /*-------------Navbar-css-start---------------*/

  .navbar-brand {
    max-width: 180px;
    width: 180px;
  }

  /*-------------Navbar-css-end---------------*/
}


@media only screen and (max-width: 1199px) {
  .tw_c-right a {
    width: 50%;
    margin-left: auto;
  }

  /*-------------updates-css-start---------------*/
  .cp_box-wrapper {
    margin: 20px 80px;
  }

  .sw_a-wrap {
    margin: 10px 80px 40px;
  }

  .result_c-wrap {
    margin: 30px 80px;
  }

  .an_b-wrap {
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  }

  .topic_w-wrapper {
    grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
  }

  .at-btn .ay_time span,
  .yt-btn .ay_time span {
    font-size: 14px;
  }

  .ay_time-wrap img {
    max-width: 28px;
  }

  .qm_wrapper {
    margin: 20px 80px 20px;

  }

  .page_baneer.bill_banner {
    padding: 20px;
  }

  .qm_boookmark {
    padding: 20px 10px;
  }

  /*-------------updates-css-end---------------*/

  /*-------------Navbar-css-start---------------*/

  .navbar-brand {
    max-width: 140px;
    width: 140px;
  }

  .navbar {
    padding: 0;
  }

  .ac-box {
    margin: 0 10px 0 0;
  }

  /*-------------Navbar-css-end---------------*/

  /*-------------Main Banner-css-end---------------*/


  .banner-one {
    padding: 100px 30px 110px 60px;
  }

  .banner-one h1 {
    font-size: 50px;
  }

  .banner-one p {
    font-size: var(--md-font);
  }

  .main-banner-wrapper {
    height: fit-content;
    max-height: fit-content;
  }

  .active-student {
    left: 13%;
  }

  /*-------------Main Banner-css-end---------------*/

  /*-------------App Banner Section Start---------------*/

  .app-left_arrow {
    margin: -60px 0 25px 0;

  }

  .app-banner-wrapper {
    border-radius: 0 0 50px 50px;
  }

  /*-------------App Banner Section end---------------*/


  /*-------------Experts Section start -css-start---------------*/

  .experts_box {
    max-width: 75%;
    margin: 30px auto 50px;
  }

  .experts_right {
    margin-left: 10%;
  }

  .experts_box p {
    font-size: var(--smm-font);
  }

  /*-------------Experts Section start -css-end---------------*/

  /*-------------Experience Advanced Section start---------------*/
  #ex-advanced .b-group {
    display: none;
  }

  .re-b-1 {
    right: 0;
  }

  /*-------------Experience Advanced Section End---------------*/

  /*-------------Bloge Section start -css-start---------------*/

  .bloge_details .dark-btn h4 {
    font-size: var(--smm-font);
  }

  .get_sub-wrap {
    padding: 40px 80px;
  }

  .offer_box {
    justify-content: center;
  }

  .offer_box.practice_b {
    box-shadow: 0 0 0 2px var(--d-purple);

  }

  .offer_box.test_sb {
    box-shadow: 0 0 0 2px var(--l-blue);

  }

  .offer_box.pyq_b {
    box-shadow: 0 0 0 2px var(--l-orange);

  }

  /*-------------Bloge Section start -css-end---------------*/

  /*-------------Students Reviews Section strat---------------*/

  .review-slider p {
    font-size: var(--smm-font);
    margin: 0 0 15px;
  }

  /* .review-heading p{
    font-size: var(--smm-font);
} */
  .review-slider h3 {
    font-size: var(--xmd-font);
  }

  .review-heading {
    padding: 85px 0px 0 115px;

  }

  .review-box .owl-carousel {
    margin: 30px -90px 30px 50px;
  }

  .review-box {
    width: 50%;
  }

  .review-box .app-left_arrow {

    right: 40px;
    bottom: -75px;
    width: 25%;
  }

  /*-------------Students Reviews Section end---------------*/

  /*-------------Target Exams-css-start---------------*/

  .page_banner-wrapper,
  .target-exam,
  .exam_catagory {
    padding: 0 80px;
  }

  /*-------------Target Exams-css-end---------------*/

  /*-------------s plan-css-start---------------*/

  .s_plan-wrapper {
    margin: 30px 80px;
  }

  .s_plan {
    grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr));
  }

  /*-------------s plan-css-end---------------*/


  /*-------------Subscription-plan start-css-start---------------*/



  .sub_card span {
    font-size: var(--smm-font);
  }

  .bill_box .rs_bar h4,
  .bill_box .rs_bar p,
  .sub_card p {
    font-size: var(--xmd-font);
  }

  /*-------------Subscription-plan start-css-end---------------*/


  /*-------------Bill Info-css-start---------------*/

  .bill_info {
    width: 100%;
    max-width: 100%;
  }

  .user_co-r {
    max-width: 90%;
  }

  /*-------------Bill Info-css-end---------------*/

  /*-------------Congrats-info-css-start---------------*/
  .congrets_box h1 {
    font-size: var(--sb-font);
  }

  .congrets_box h5 {
    font-size: var(--xmd-font) !important;
  }

  .congrets_box p {
    font-size: var(--smm-font);
  }

  .congrets_box h2 {
    font-size: var(--xxl-font);
  }

  .congrets_box {
    max-width: 68%;
  }

  /*-------------Congrats-info-css-end---------------*/

  /*-------------My-order-css-start---------------*/

  .dow_invoice-wrapper {
    /* flex-direction: column;
  justify-content: center !important; */
  }

  .dow_invoice-wrapper p,
  .dow_invoice button {
    margin: auto 0 auto auto;
  }

  /*-------------My-order-css-end---------------*/

  /*-------------Experience Advanced Section start---------------*/

  .topic_wrapper {
    margin: 0 80px;
  }

  /*-------------Experience Advanced Section End---------------*/

  /*-------------side bar css start---------------*/

  /* .side_bar ul{
      padding: 10px;
    } */

  /*-------------side bar css End---------------*/

  /*-------------Topic-wise-sec-css-start---------------*/

  .tw_card {
    display: block;
  }

  .tw_c-left {
    width: 100%;
  }

  .tw_c-center {
    width: 100%;
    margin: 30px 0;
  }

  .tw_c-right {
    width: 100%;
    grid-gap: 10px;
  }

  /*-------------Topic-wise-sec-css-end---------------*/
  /*------------footer-sec-css-start---------------*/

  .offer_card-wrapper {
    margin: 30px 80px;
  }

  .offers_card-left h1 {
    font-size: 60px;
  }

  .offer_card {
    padding: 30px 40px;
  }

  .offers_card-right h4 {
    font-size: var(--sm2-font);
  }

  .footer_wrapper {
    padding: 50px 100px 20px;
  }

  /*------------footer-sec-css-end---------------*/

}

@media only screen and (max-width: 1399px) {

  /*-------------Rg section-css-start---------------*/
  .list_sc-wrapper {
    grid-template-columns: repeat(auto-fill, minmax(31rem, 1fr));
  }

  .rg-bar {
    padding: 100px 90px 400px;
  }

  /*-------------Rg section-css-end---------------*/

  /*-------------Right-for-you-Section-css-start---------------*/

  .asses-card {
    max-width: 100%;
    padding: 45px 20px;
    margin: auto auto 20px auto;
  }

  /*-------------Right-for-you-Section-css-end---------------*/

  /*-------------Counter-board-Section-css-end---------------*/

  .counter_box h1 {
    font-size: var(--b-font);
  }

  #counter {
    padding: 80px 80px;
  }

  /*-------------Counter-board-Section-css-end---------------*/

  /*-------------Experts Section start -css-start---------------*/

  .experts_card {
    margin: 10px 0;
  }

  .experts_right-inner {

    margin: 60px auto -90px 60px;
  }

  .experts_box h1 {
    margin-bottom: 35px;
  }

  /*-------------Experts Section start -css-end---------------*/

  /*-------------Bloge Section start -css-start---------------*/

  #bloge {
    padding: 140px 0px 90px 80px;
  }

  /* .bloge_details{
        width: 35%;
    }
    .bloge_slider{
        width: 65%;
    } */

  /*-------------Bloge Section start -css-end---------------*/

  /*-------------Students Reviews Section start---------------*/

  .review-box .app-left_arrow {
    width: 30%;
  }

  /*-------------Students Reviews Section end---------------*/

  /*-------------Experience Advanced Section start---------------*/

  .topic_box .plan-card h4 {
    max-width: 50%;
  }

  .topic_box .s_plan .plan-card {
    padding: 20px;
  }

  /*-------------Experience Advanced Section End---------------*/

  /*-------------Topic-css-start---------------*/

  .bar_progress {
    display: block;
  }

  .bar_progress h5 {
    width: auto;
    margin-bottom: 10px;
  }

  .bar_line-wrapper {
    width: 100%;
  }

  .bar_progress {
    margin-bottom: 20px;
  }

  .bar_progress h4 {
    margin-left: 20px;
  }

  /*-------------Topic-css-end---------------*/

}

@media only screen and (max-width: 1499px) {

  .w_img {
    width: 120px;
    height: 120px;
  }

  .w_rank {
    padding: 5px 0;
  }

  .winner_stage span {
    font-size: var(--sm-font);
  }

  .w_rank h5 {
    font-size: var(--sm2-font);
  }

  /*-------------Congrats-info-css-start---------------*/

  .congrets_box img {
    max-width: 300px;
  }

  .congrets_box h5 {
    font-size: var(--xxl-font);
  }

  /*-------------Congrats-info-css-end---------------*/


}

@media only screen and (min-width: 1600px) {
  .an_b-wrap {
    grid-template-columns: repeat(auto-fill, minmax(34rem, 1fr));
  }

  .list_sc-wrapper {
    grid-template-columns: repeat(auto-fill, minmax(38rem, 1fr));
  }
}

@media only screen and (max-width: 1599px) {

  /*-------------Experience Advanced Section start---------------*/
  .bo_an_b-wrap {
    grid-template-columns: repeat(auto-fill, minmax(28rem, 1fr));
  }

  .an_b-wrap {
    grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
  }

  #ex-advanced .b-group {
    top: unset;
    bottom: -15%;
  }

  /*-------------Experience Advanced Section End---------------*/



}


@media only screen and (min-width: 1200px) and (max-width: 1399px) {


  /*-------------chapter-css-start---------------*/

  .cp_box-wrapper {
    /* max-width: 70%; */
  }


  /*-------------chapter-css-end---------------*/

}