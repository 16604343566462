@-webkit-keyframes full-r {
    from {
            -webkit-transform: rotate(0deg);
    }
    to {
            -webkit-transform: rotate(359deg);
    }
}
@-webkit-keyframes smooth-r {
        from {
                -webkit-transform: rotate(0deg);
        }
        to {
                -webkit-transform: rotate(359deg);
        }
    }
    @-webkit-keyframes smooth-l {
        from {
                -webkit-transform: rotate(359deg);
        }
        to {
                -webkit-transform: rotate(0deg);
        }
    }
@-webkit-keyframes mover {
        0% { transform: translateY(0); }
        100% { transform: translateY(-10px); }
    }
    @-webkit-keyframes wobble {
        0% {
          -webkit-transform: none;
          transform: none;
        }
        15% {
          -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
          transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
        }
        30% {
          -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
          transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
        }
        45% {
          -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
          transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
        }
        60% {
          -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
          transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
        }
        75% {
          -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
          transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
        }
        100% {
          -webkit-transform: none;
          transform: none;
        }
      }
      @keyframes wobble {
        0% {
          -webkit-transform: none;
          transform: none;
        }
        15% {
          -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
          transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
        }
        30% {
          -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
          transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
        }
        45% {
          -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
          transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
        }
        60% {
          -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
          transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
        }
        75% {
          -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
          transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
        }
        100% {
          -webkit-transform: none;
          transform: none;
        }
      }
      /* @keyframes fill{
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(135deg);
  }
} */